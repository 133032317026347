import { nanoid } from 'nanoid';

// HERO DATA
export const heroData = {
  name: 'Brian Price',
  subtitle: 'Software Engineer',
  cta: 'Learn more',
};

// ABOUT DATA
export const aboutData = {
  img: 'picture.jpg',
  paragraphOne:
    "I'm a full-stack engineer who loves the thrill and challenge that comes with creative problem solving.  I've worked with JavaScript, React, React Native, Express, PostgreSQL, and MongoDB, among other technologies.",
  paragraphTwo:
    "I'm currently an Engineer at Honeywell, after having worked two years for Topsheet.  Prior to that I built a number of solo projects, as well as several team projects while participating in a rigorous software engineering immersive program.",
  paragraphThree: "When I'm not coding, some of my hobbies are trivia, exercise, piano, and dogs.",
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'TV Tracker.jpg',
    title: 'TV Tracker',
    info:
      'This app allows users to create an account in which they can add shows to their favorites list, and check off episodes as they watch them.  Additionally, users can opt to receive an email notification on the day a show airs, with the relevant information for that episode.',
    info2: 'React, Express, PostgreSQL',
    url: 'https://tv-tracker-brianprice.herokuapp.com/',
    repo: 'https://github.com/brianprice88/TV-Tracker',
  },
  {
    id: nanoid(),
    img: 'Rock Paper Scissors.jpg',
    title: 'Rock Paper Scissors',
    info:
      'This online multiplayer game allows users to create a private room or join an existing room, in which they can compete against a friend.',
    info2: 'React, Socket.IO, Express',
    url: 'https://rock-paper-scissors-brianprice.herokuapp.com/',
    repo: 'https://github.com/brianprice88/Rock-Paper-Scissors',
  },
  {
    id: nanoid(),
    img: 'Minesweeper.jpg',
    title: 'Minesweeper',
    info:
      'This implementation of the classic game features multiple difficulty levels, with option for players to save high scores.',
    info2: 'HTML, CSS, JavaScript, MongoDB',
    url: 'https://minesweeper-brian-price.herokuapp.com/',
    repo: 'https://github.com/brianprice88/Minesweeper',
  },
];

// CONTACT DATA
export const contactData = {
  btn: 'Email Me',
  email: 'brianprice88@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://linkedin.com/in/brian-price88',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/brianprice88',
    },
    {
      id: nanoid(),
      name: 'angellist',
      url: 'https://angel.co/u/brian-price-15',
    },
  ],
};
